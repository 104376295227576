.custom-pagination {
  display: flex;
  justify-content: center;
  gap: 8px; /* Ensure there is a gap between pagination items */
}

.custom-pagination .custom-page-link {
  border-radius: 50% !important; /* Makes the buttons rounded */
  width: 30px; /* Controls the width of the buttons */
  height: 30px; /* Controls the height of the buttons */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; /* Removes extra padding */
  margin: 0; /* Ensures no extra margins interfere */
}

.custom-pagination .page-item {
  display: flex;
}

.custom-pagination .page-item + .page-item {
  margin-left: 8px; /* Add space between each page-item */
}

.custom-pagination .page-item.active .custom-page-link {
  background-color: rgba(
    33,
    37,
    41,
    0.75
  ) !important; /* Adjust this to your primary color */
  color: white;
  border-color: rgba(33, 37, 41, 0.5) !important;
  border-radius: 50%;
}

.custom-pagination .custom-page-link:hover {
  background-color: rgba(
    33,
    37,
    41,
    0.75
  ) !important; /* Adjust this to your primary color */
  color: white;
}

.custom-pagination .page-item.disabled .custom-page-link {
  background-color: #e9ecef;
  color: #6c757d;
}
