.team-members-container {
  padding: 0.9375rem 2.0625rem;
}

.team-members-container button.accordion-button {
  /* margin-top: 0.875rem; */
  color: #6b7889;
  background-color: #f2f2f5;
  font-family: "Inter", "Poppins", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.625rem 1.25rem;
}

.team-members-container .accordion-body {
  background-color: #f2f2f5;
}

.team-members-container h5 {
  color: #6b7889;
  font-size: 0.875rem;
  font-weight: 700;
}

.team-members-container .table-header {
  background-color: var(--bg-color);
  border-radius: 0.3125rem;
  padding: 0.375rem 5%;
}

.team-members-container .table-header div {
  width: 40%;
  color: #6b7889;
  font-size: 0.875rem;
  font-weight: 500;
}

.team-members-container .table-header .message-state-icon {
  width: 10%;
}

.team-members-container .table-body {
  background-color: #f2f2f5;
  border-radius: 0.3125rem;
  padding: 0.5rem 5%;
  transition: all 0.3s ease-in-out;
}

.team-members-container .table-body span.numbers {
  width: 10%;
  /* padding-left: 0.3125rem; */
  color: var(--text-name-color);
  font-size: 0.75rem;
  font-weight: 400;
}

.team-members-container .table-body div {
  width: 40%;
  color: #6b7889;
  font-size: 0.75rem;
  font-weight: 400;
}

.team-members-container .table-body div .team-member-img {
  position: relative;
  display: inline-block;
  padding-left: 0;
}

.team-members-container .table-body div .team-member-img img {
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
}

.team-members-container .table-body div .team-member-img span.icon {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
}

.team-members-container .table-body:hover {
  background-color: #dadde1;
  cursor: pointer;
}

.team-members-container .active-table-body {
  background-color: #dadde1;
  cursor: pointer;
}
