.project-info {
  height: auto;

  th,
  tr td {
    padding: 0.25rem;
  }

  ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: $bg-chatlist-color;
    border-radius: 0.5rem 0.5rem 0.9375rem 0.9375rem;
  }

  ::-webkit-scrollbar-thumb {
    background: $text-name-color;
    border-radius: 0.5rem 0.5rem 0.9375rem 0.9375rem;
  }
}
