.performance-page {
  background-color: white !important;
  padding: 2rem 2.1875rem 2.1875rem;
  height: calc(100vh - 8rem) !important;
  border-radius: 0.6rem;
  // width: 100%;
  h4 {
    color: $text-name-color;
    font-size: 1.5rem;
    font-family: $font-family-base;
    font-weight: 500;
  }
  .filters-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    // align-items: stretch !important;
  }
  // .filters-row .css-14gbl45-control {
  //   height: 3rem !important;
  //   padding: 0 !important;
  //   margin: 0 !important;
  //   overflow: hidden !important;
  // }
  // .filters-row .css-14gbl45-control .css-1jqq78o-placeholder {
  //   margin-bottom: 5px;
  // }
  // .filters-row .css-14gbl45-control .css-1u9des2-indicatorSeparator {
  //   visibility: hidden;
  // }
  // .filters-row .css-14gbl45-control .css-tj5bde-Svg {
  //   visibility: hidden;
  // }
  .broadcast-filter-date-picker {
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    color: #6b7889;
    height: 3rem;
    padding: 0 1.6rem;
    transition: transform 0.3s ease-out;
    width: 100%;
  }

  .table-performance {
    border: 1px solid #ddd;
    height: calc(100vh - 22rem) !important;
    overflow-y: auto;
  }

  .reset-filters-report {
    // padding: 0.2rem 0.75rem;
    border-radius: 0.625rem;
    background: #6b7889 !important;
    color: #fff;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    border: 1px solid #6b7889 !important;
    height: 3rem !important;
    width: 3rem;
  }
  .reset-filters-report:hover {
    background: #5c6877 !important;
    transition: all 0.3s ease-in-out;
  }

  .download-filters-report {
    border: 0;
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.625rem;
    border: 1px solid #6b7889 !important;
    color: #6b7889;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }
  .download-sheet-icon svg path {
    transition: all 0.3s ease-in-out;
  }
  .download-filters-report:hover {
    background: #6b7889 !important;
    color: #fff;
    .download-sheet-icon svg path {
      stroke: #ffffff;
    }
  }
}
