.text-ticket-filter {
  color: #74788d;
  transition: all 0.3s;
  cursor: pointer;
}

.text-ticket-filter:hover {
  color: #5d6071;
  font-weight: 600;
}

.text-ticket-filter.active {
  color: #5d6071;
  font-weight: 600;
}

.fitler-input-style {
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 0.5rem;
  color: #6b7889;
  transition: transform 0.3s ease-out;
}
