.replied-message {
  background-color: #f5f5f5;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  padding: 8px;
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 10px;
  /* margin-bottom: -10px; */
  clear: both;
  text-align: left;
  opacity: 0.8;
  border: 1px solid #efefef;
}

.replied-message .sender-name {
  font-weight: bold;
  margin-bottom: 4px;
  color: white;
}

.replied-message .message-text {
  padding-left: 8px;
  margin-bottom: 5px;
  text-align: justify;
}

.users-replyed-message {
  background: rgba(0, 0, 0, 0.15);
  border: none;
  padding: 8px 12px 8px 16px;
  color: rgba(255, 255, 255, 0.7);
  border-left: 4px solid white;
}
