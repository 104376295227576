.user-setting-page {
  height: calc(100vh - 5rem) !important;
  background-color: $bg-chatlist-color;
  padding: 2rem 2.1875rem 2.1875rem;
  .svg-user-table {
    width: 0.5rem;
    height: 0.5rem;
  }
  .users-setting p {
    color: #8d9eb5;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;

    .users-setting-span {
      color: #6b7889;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
    }
  }
  .users-setting-active h5 {
    color: #6b7889;
    font-size: 1.5rem;
  }

  .users-setting-active p {
    color: #6b7889;
    font-size: 1.125rem;
  }

  .users-setting button {
    border: 0;
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.625rem;
    background: #6b7889;
    color: #fff;
    font-size: 1.125rem;
    margin-bottom: 1.75rem;
  }
  .reset-filters {
    // padding: 0.2rem 0.75rem;
    border-radius: 0.625rem;
    background: #6b7889;
    color: #fff;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    border: 1px solid #6b7889 !important;
    height: 3rem !important;
    width: 3rem;
  }

  .user-status-item {
    width: 33%;
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #e4e5e6;
    cursor: pointer;
  }
  .active-filter {
    border: 1px solid #bbbbbb;
  }

  .filter-button {
    border: 0;
    background-color: transparent;
    color: #8d9eb5;
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
  }
  .active-user-role-filter {
    color: #6b7889;
    font-size: 1.125rem;
    font-weight: 600;
  }
}
.table-container-user {
  height: calc(100vh - 24rem);
}
.table-container-team {
  height: calc(100vh - 24rem);
}

.table-container {
  // height: calc(100vh - 32rem) ;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 0.625rem;
  .loading {
    height: calc(100vh - 25rem);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  table {
    box-shadow: 0px -1px 0px 0px #e1e1e1 inset;
    width: 100%;

    tr {
      border-bottom: 1px solid #e0e0e0;
    }
    th {
      padding: 1.2rem;
      color: #6b7889;
      font-weight: 600;
      font-size: 1rem;
    }
    td {
      color: #6b7889;
      // padding:.8rem ;
      font-size: 1rem;
      height: 4.125rem;
    }
    .user-email {
      font-size: 0.75rem;
      color: #7f7f7f;
    }
  }

  .empty-avatar {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background-color: #ccc;
  }
  .user-status {
    padding: 0.281rem 0.562rem;
    border-radius: 0.625rem;
    font-size: 0.843rem;
  }

  .away-status {
    background: var(--Status-BG-Grey, #e4e4e4);
    color: var(--Status-Text-Grey, #6b7889);
  }
  .service-hours-status {
    background: var(--Status-BG-Orange, #fff2dd);
    color: var(--Status-Text-Orange, #d98634);
  }
  .online-status {
    background: var(--Status-BG-Green, #e9ffef);
    color: var(--Status-Text-Green, #409261);
  }
  .offline-status {
    background-color: #ffcaca;
    color: #ff0404;
  }
}

.filter-user {
  box-shadow: 0px 1px 4px 0px #e4e5e6;
  color: #6b7889;
  font-weight: 500;
  font-size: 1rem;
}

.filter-user-input {
  padding: 0.35rem 1.75rem 0.35rem 1.5rem;
  border-radius: 0.5rem;
  border: 0;
  //   color: #c2c7d0;
  font-size: 1rem;
  width: 20rem;
  height: 3rem;
  // flex: 1;
}

.action-svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.action-svg-delete {
  transition: stroke 0.3s ease;
}

.action-svg-delete:hover path {
  fill: red;
}
.action-svg-edit:hover path {
  stroke: #5d5d5d;
}
.action-svg-restore:hover path {
  fill: #5d5d5d;
}

.m-mb-2 {
  margin-bottom: 0.625rem !important;
  .p-5px {
    padding: 0.4rem;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.158);
  z-index: 9999;
}
.delete-popup {
  .delete-message {
    font-size: 1.125rem;
    // margin-top: 0.625rem !important;
  }
  .delete-button {
    border: 0;
    font-size: 1rem;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    width: 5.6rem;
    margin-bottom: 2rem;
  }
  .delete {
    background: #f44336;
  }

  .cancel-delete {
    background-color: transparent;
    border: 1px solid #6b7889;
    color: #6b7889 !important;
    transition: all 0.3s;
  }
  .cancel-delete:focus {
    background-color: transparent !important;
  }
  .cancel-delete:hover {
    background-color: #ececec !important;
  }
}

.edit-popup {
  font-size: 1.125rem;
  margin-top: 0.625rem !important;
  .edit-button {
    border: 0;
    font-size: 1rem;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    width: 5.6rem;
    // margin-bottom: 2rem;
  }
  .cancel-edit {
    background-color: transparent;
    border: 1px solid #6b7889;
    color: #6b7889 !important;
    transition: all 0.3s;
  }
  .cancel-delete {
    background-color: transparent !important;
    border: 1px solid #6b7889;
    color: #6b7889 !important;
    transition: all 0.3s;
  }
  .cancel-delete:focus {
    background-color: transparent !important;
  }
  .cancel-delete:hover {
    background-color: #ececec !important;
  }
}

// Success add

.success-message {
  position: absolute;
  top: 6.5rem;
  right: 0.9rem !important;
  z-index: 999;
  .toast {
    background-color: transparent !important;
    border-radius: 0.625rem !important;
  }
  .success-message-bg {
    background-color: #defde6;
    border-radius: 0.625rem !important;
    box-shadow: 0px 4px 4px 0px #0000000b;
    padding: 1.5rem;
    width: 30rem;
    color: #409261;
  }
}
.delete-message-bg {
  background-color: #ffcaca;
  border-radius: 0.625rem !important;
  box-shadow: 0px 4px 4px 0px #0000000b;
  padding: 1.5rem;
  // width: 25rem;
  color: #ff0404;
}
.edit-message-bg {
  background-color: #bee5eb;
  border-radius: 0.625rem !important;
  box-shadow: 0px 4px 4px 0px #0000000b;
  padding: 1.5rem;
  width: 30rem;
  color: #0c5460;
}
.phone-add {
  .react-international-phone-input-container .react-international-phone-input {
    width: 100%;
  }

  .country-list .search-box {
    width: 90%;
  }
  .react-tel-input .form-control {
    width: 100%;
  }
  .react-tel-input .search-emoji {
    display: none !important;
  }
}

// view ticket:
.item-user {
  box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
  border-radius: 0.6rem;
  .user-ticket {
    background-color: #e0e0e1;
    border-radius: 0.6rem 0.6rem 0 0;
    h5 {
      padding: 1rem;
    }
  }
  .ticket {
    padding: 0.4rem 1rem;
    background-color: #f2f2f5;
    border-radius: 0.6rem;
  }
  .user-ticket-data {
    padding: 0rem 1rem 1rem 1.2rem;
    p {
      color: #6b7889;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;

      span {
        color: #8d9eb5;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}
