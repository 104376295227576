.Conversations-details{
    border: 1px solid #6B7889;
    border-radius: 0.6rem;
    background-color: white;
    h5{
        background-color: #e3e0e0;
        padding: 1rem 2rem !important;
        border-radius: 0.6rem 0.6rem 0 0 ;
    }
    p{
        padding: 1rem 2rem !important;
    }
}