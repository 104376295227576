.chat-page {
  max-height: calc(100vh - 5rem);
  background-color: #ebedf0;
  height: 100%;
  padding: 2rem;

  .conversation-box {
    padding: 0 0.875rem;
    width: 58%;
    max-width: 58%;
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0rem 0.25rem 0.25rem 0rem #e1e1e1;
    height: 100%;

    .sending-template-overlay-loading {
      position: absolute;
      z-index: 223;
      background: rgba(255, 255, 255, 0.3);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .btb {
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      bottom: 6.625rem;
      right: 2.125rem;
      z-index: 777;
      bottom: 106px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;

      &:focus,
      &:active {
        outline: none;
      }
    }

    .template-layout {
      position: absolute;
      top: 5.1875rem;
      background: white;
      height: calc(100% - 11.1875rem);
      width: 100%;
      left: 0;
      padding: 1rem 2.5rem;
      overflow: auto;
      z-index: 222;

      .show-templates {
        .go-back {
          display: inline-block;
          cursor: pointer;
        }

        .templates-container {
          .template {
            margin-bottom: 0;
            color: #6b7889;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            text-align: center;
            display: inline-flex;
            padding: 0.5rem 1.5rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            border-radius: 0.5rem;
            border: 0.0625rem solid rgba(107, 120, 137, 0.25);
            background: #fff;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              color: white;
              background: #8d9eb5;
            }
          }

          .template-header {
            color: #6b7889;
            font-family: Inter;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }
    }

    .message-reply {
      position: absolute;
      background: white;
      width: 100%;
      bottom: 6rem;
      z-index: 111;
      box-shadow: 0rem -0.125rem 0.25rem 0rem rgba(107, 120, 137, 0.25);
      left: 0;
      padding: 0.5rem;
      transition: all 0.3s;

      &-content {
        border-left: 0.25rem solid #6b7889;
        background: rgba(141, 158, 181, 0.25);
        padding: 0.5rem 0.75rem 0.5rem 1rem;
        border-radius: 0.5rem;
      }
    }

    .sending-files-preview {
      position: absolute;
      border-radius: 0.625rem;
      background: white;
      width: 60%;
      bottom: 6rem;
      overflow: hidden;
      z-index: 111;
      box-shadow: 0rem -0.125rem 0.25rem 0rem rgba(107, 120, 137, 0.25);
      left: 0;
      transition: all 0.3s;

      &-header {
        padding: 0.5rem;
        background: #f2f2f7;

        .add-icon {
          cursor: pointer;
        }
      }

      .imgs {
        overflow: auto;

        &::-webkit-scrollbar {
          width: 0.2rem;
          height: 0.2rem;
          transition: all 0.3s ease;
        }

        &:hover::-webkit-scrollbar {
          width: 0.4rem;
          height: 0.4rem;
        }

        .file-container {
          min-height: 100px;
          min-width: 100px;
        }

        .img-container,
        .file-container {
          .delete-message {
            position: absolute;
            right: 0;
            top: 0;
            background-color: #fff;
          }

          img {
            max-width: 3.75rem;
            max-height: 3.75rem;
          }

          &-data {
            width: 100%;
            height: 100%;
          }

          &-icon-container {
            width: 20px;
            height: 20px;
          }
        }
      }

      .video-preview {
        max-width: 18.75rem;
        max-height: 12.5rem;
      }
    }

    .conversation-header {
      padding: 1.1875rem 0.625rem;
      border-bottom: 1px solid #ebedf0;

      .user-details {
        gap: 1rem;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        h2 {
          color: #6b7889;
          font-family: Inter;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5625rem; /* 25px */
        }
      }

      .chat-tools {
        padding: 0 10px;
        cursor: pointer;

        &-toggler {
          background: white;
          width: 44px;
          border-radius: 50%;
          height: 44px;
          border: 1px solid white;

          &:hover {
            border: 1px solid #e3e3e3;

            svg {
              cursor: pointer;

              circle {
                transition: all 0.3s ease-in-out;
              }
            }

            &:hover {
              svg {
                circle {
                  fill: #6b7889;
                }
              }
            }
          }
        }

        &-change-name {
          button {
            padding: 3px 14px;
          }
        }
      }
    }

    .messages-container {
      overflow-y: auto;
      flex: 1 1;
      gap: 0.5rem;
      margin: 1rem 0;
      padding: 0 0.625rem;
      transition: all 0.3s;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #fff;
        transition: all 0.3s ease-in-out;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: #e8ecf0;
      }

      .message-actions-container {
        transition: all 0.3s;

        .message-actions {
          transition: all 0.3s;
          opacity: 0;
        }

        &:hover .message-actions {
          opacity: 1;

          .message-action {
            cursor: pointer;
            transition: all 0.3s;
          }

          .message-action-reply:hover path {
            fill: #6b7889;
          }

          .message-action-react:hover path {
            stroke: #6b7889;
          }
        }
      }

      .message {
        // min-height: 2.4375rem;
        // max-width: 79%;
        padding: 0.5rem;
        align-items: flex-start;
        // gap: 0.5rem;
        border-radius: 0.375rem;
        background: #6b7889;
        color: #fff;
        position: relative;
        margin-left: 0.625rem;
        transition: all 0.3s ease-in-out;

        // &-actions {
        //   .message-action {
        //     opacity: 0;
        //     transition: all 0.3s ease-in-out;
        //   }
        // }

        &-action-body {
          color: #8d9eb5;
          text-align: center;
          font-family: Inter;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }

        &-client-body {
          color: #fff;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
        }

        .reacts-on-client-message {
          position: absolute;
          display: inline-block;
          padding: 0.125rem;
          color: #6b7889;
          left: 0.4375rem;
          bottom: -0.6875rem;
          font-size: 0.625rem;
          border-radius: 18px;
          background: #fff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(107, 120, 137, 0.25);
        }

        .reacts-on-team-member-message {
          position: absolute;
          display: inline-block;
          padding: 0.125rem;
          color: #6b7889;
          right: 0.4375rem;
          bottom: -0.6875rem;
          font-size: 0.625rem;
          border-radius: 18px;
          background: #fff;
          box-shadow: 0rem 0rem 0.25rem 0rem rgba(107, 120, 137, 0.25);
        }

        &-client-time {
          color: #fff;
          font-family: Inter;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &-member-body {
          color: #6b7889;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
        }

        &-member-time {
          color: #8d9eb5;
          font-family: Inter;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .team-member-role {
          color: #8d9eb5;
          font-family: Inter;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &::first-letter {
            text-transform: capitalize;
          }
        }

        .team-member-name {
          color: #6b7889;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.125rem;

          &::first-letter {
            text-transform: capitalize;
          }
        }

        .arrow-tooltip {
          display: block;
          z-index: 4;
          width: 1.25rem;
          height: 1.25rem;
          position: absolute;
          left: -0.75rem;
          top: -0.125rem;
          border-radius: 0 0 0 1.25rem;
        }

        .team-arrow-tip {
          display: block;
          z-index: 4;
          width: 1.25rem;
          height: 1.25rem;
          position: absolute;
          right: -0.625rem;
          top: -0.125rem;
          border-radius: 0 0 0 1.25rem;
        }
      }

      .secret-message {
        background-color: #ffe6b9 !important;
      }

      .secret-client-message {
        background-color: #e3b15a !important;
      }

      .message-action {
        background-color: white;
        margin-left: 0;
        padding: 0;
      }

      .react-on-mesage-toggler {
        background: transparent;
        border: none;

        outline: none;

        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }

      .react-on-message {
        padding: 0.5625rem 1.125rem;
        border-radius: 2.7975rem;
        background: #fff;
        box-shadow: 0.373rem 0.746rem 2.611rem 0rem rgba(10, 10, 10, 0.16);

        .react-emoji {
          padding: 0.25rem;
        }
      }

      .team-message-container {
        .user-img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
        }

        .hide-img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          opacity: 0;
        }
      }

      .team-message {
        background-color: #ebedf0;
        color: #6b7889;
        margin-right: 0.5rem;
      }
    }

    .create-message {
      padding: 1.5rem 0.625rem;
      gap: 1.5rem;

      .suggested-answers {
        width: 75%;
        position: absolute;
        top: -52vh;
        height: 52vh;
        max-height: 52vh;
        z-index: 111;
        margin-left: 12.5%;
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        border-bottom: 0.0625rem solid #e0e6e9;
        box-shadow: 0px -2px 4px 0px rgba(107, 120, 137, 0.25);
        transition: all 0.3s;

        &-header {
          border-radius: 0.625rem 0.625rem 0rem 0rem;
          border-bottom: 0.0625rem solid #e0e6e9;
          background: #f2f2f7;

          h3 {
            color: #6b7889;
            font-family: Inter;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        &-body {
          padding: 0.625rem 1.5rem;
          height: calc(52vh - 3.4375rem);
          max-height: calc(52vh - 3.4375rem);
          overflow: auto;
          background-color: white;

          .suggested-answer-item {
            cursor: pointer;
            border-radius: 0.3125rem;
            padding: 0.3125rem 0.625rem;
            transition: all 0.3s;

            &:hover {
              background: #e9e9e9;
            }
          }
        }
      }

      .send-messages-popup {
        position: absolute;
        top: -13.75rem;
        left: 0;
        z-index: 10;
        border-radius: 0.75rem;
        background: #fff;

        box-shadow: 0rem 0.375rem 1.25rem 0rem rgba(13, 10, 44, 0.1);

        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 1.5rem;
        padding: 1.5rem;

        .send-attachemnt-toggler {
          &:focus,
          &:active {
            box-shadow: none;
          }
        }

        .icon-container {
          cursor: pointer;

          .file-input {
            display: none;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }

          .icon {
            display: inline-block;
            height: 2.625rem;
            width: 2.625rem;
            border-radius: 50%;
            background: linear-gradient(126deg, #8d9eb5 0%, #6b7889 86.18%);
            color: white;
          }
        }
      }

      textarea {
        border-radius: 0.625rem;
        border: 2px solid #e2e8f0;
        background: #fff;
        resize: none;
        padding: 0.625rem 1.25rem;
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 400;
      }

      textarea::placeholder {
        color: #000;
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3125rem;
        opacity: 0.4;
      }

      textarea.secretMSG {
        background-color: #fff3dc;
      }

      svg {
        transition: all 0.3s;
        // cursor: pointer;
      }

      .suggested-answers-icon-container {
        position: absolute;
        cursor: pointer;
        right: 1rem;
        top: 42%;
        transform: translateY(-50%);

        svg {
          path {
            transition: stroke 0.3s;
          }
        }

        &:hover svg path {
          stroke: #6b7889;
        }
      }

      .send-btn {
        cursor: pointer;
        height: 3rem;
        width: 3rem;
        background-color: #6b7889;
        border-radius: 50%;
      }

      .icon-container {
        cursor: pointer;
      }

      .not-allowed-to-send {
        cursor: not-allowed;
      }
    }
  }

  .notes-box {
    width: 40%;
    max-width: 40%;
    gap: 1.875rem;

    .notes-box-tabs {
      .single-tab {
        padding: 0.6875rem 2.875rem;
        cursor: pointer;

        color: #8d9eb5;
        font-family: Inter;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.6875rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          box-shadow: 0rem 0.25rem 0.25rem 0rem #e1e1e1;
        }
      }

      .active-single-tab {
        color: #6b7889;
        border-radius: 0.625rem;
        background: #fff;
        box-shadow: 0rem 0.25rem 0.25rem 0rem #e1e1e1;

        &:hover {
          box-shadow: 0rem 0.15rem 0.15rem 0rem #e1e1e1;
        }
      }
    }

    .tab-content-notes {
      padding: 0 0.625rem;
      gap: 0.5rem;
      border-radius: 0.625rem;
      background: #fff;
      box-shadow: 0rem 0.25rem 0.25rem 0rem #e1e1e1;
      height: 200px;

      .notes-filters-container {
        padding: 0.75rem 0.875rem;

        .notes-filters {
          gap: 1.75rem;

          div {
            color: #8d9eb5;
            font-family: Inter;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5rem;
            letter-spacing: -0.023rem;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
              color: #6b7889;
              font-weight: 500;
            }
          }

          .active-filter {
            color: #6b7889;
            font-weight: 500;
          }
        }

        .add-new-note {
          padding: 0.25rem 0.5rem;
          gap: 0.25rem;
          border-radius: 5px;
          background: #6b7889;
          cursor: pointer;

          span {
            color: #fff;
            font-family: Inter;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            letter-spacing: -0.023rem;
          }
        }
      }

      .notes-container {
        padding: 0 0.875rem;
        padding-top: 0;
        margin: 0.5rem 0;
        overflow-y: auto;
        gap: 0.5rem;
        flex: 1;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #fff;
          transition: all 0.3s ease-in-out;
        }

        &::-webkit-scrollbar-track {
          background: white;
        }

        &:hover::-webkit-scrollbar-thumb {
          background: #e8ecf0;
        }

        .single-note {
          padding: 0.5rem 1.5rem;
          border-radius: 0.625rem;
          border: 1px solid #ebedf0;
          background: #fff;

          .note-header {
            gap: 0.125rem;

            h5 {
              color: #6b7889;
              font-family: Inter;
              font-size: 1rem;
              font-weight: 600;
              line-height: 1.5rem;
              letter-spacing: -0.023rem;
            }

            .note-tools {
              padding-left: 0.625rem;
              cursor: pointer;

              svg {
                cursor: pointer;

                circle {
                  transition: all 0.3s ease-in-out;
                }
              }

              &:hover {
                svg {
                  circle {
                    fill: #6b7889;
                  }
                }
              }
            }

            .note-time {
              color: #8d9eb5;
              font-family: Inter;
              font-size: 0.75rem;
              font-weight: 400;
              line-height: 1.125rem;
              letter-spacing: -0.0173rem;
            }
          }

          .note-body {
            padding: 0.25rem 0;
            color: #6b7889;
            text-align: justify;
            font-family: Inter;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.3125rem;
            letter-spacing: 0.0175rem;
          }

          .note-tags {
            gap: 0.5rem;
            padding: 0.25rem 0;
            margin-top: 0.25rem;
            flex-wrap: wrap;

            ::-webkit-scrollbar {
              width: 0.3rem !important;
              height: 0.3rem !important;
            }

            ::-webkit-scrollbar-track {
              background: $bg-chatlist-color;
              border-radius: 0.5rem 0.5rem 0.9375rem 0.9375rem !important;
            }

            ::-webkit-scrollbar-thumb {
              background: $text-name-color;
              border-radius: 0.5rem 0.5rem 0.9375rem 0.9375rem !important;
            }

            span {
              color: #fff;
              text-align: justify;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.3125rem;
              letter-spacing: -0.0201rem;
              padding: 0.125rem 0.75rem;
              border-radius: 0.5rem;
              background-color: #8d9eb5;
            }

            .alert-tag {
              background-color: #ff8383;
            }
          }
        }
      }
    }
  }
}
