.login-page {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100vh;
  background-color: $bg-color;
  position: relative;

  .left-side {
    grid-column: span 6;
    position: relative;
    background-color: black;
  }

  .left-side::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../images/login/login.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    backdrop-filter: blur(2px);
    transform-origin: center;
  }

  .right-side {
    grid-column: 8 / -2;

    .form-outer-container {
      width: 100%;
    }

    h1 {
      color: $text-color-login;
      font-size: 60px;
      font-weight: 500;
      line-height: 72px;
    }

    span {
      color: rgba(43, 43, 43, 0.5);
      font-size: 20px;
      font-weight: 400;
      line-height: 150%;
    }

    .form-container {
      margin-top: 40px;

      label {
        color: $text-color-login;
        font-size: 20px;
        font-weight: 500;
        line-height: 150%;
      }

      input {
        padding: 16px;
        border-radius: 10px;
        border: 1px solid #d7d7d7;
        line-height: 100%;
        background: #fff;
        box-shadow: 0px 1.194px 2.388px 0px rgba(0, 0, 0, 0.08);
      }

      input::placeholder {
        color: rgba(43, 43, 43, 0.5);
        font-size: 18px;
        font-weight: 400;
        vertical-align: middle;
      }

      // button {
      //   padding: 16px;
      //   color: #fff;
      //   font-size: 20px;
      //   font-weight: 500;

      //   border-radius: 10px;
      //   background: #0270ed;
      //   box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      //   transition: all 0.3s ease-in-out;
      // }

      // button:hover {
      //   background: #0366d7;
      // }
    }

    .otp-form-container {
      .otp-input {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        text-align: center;
        font-size: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      .btn-otp-go-back {
        display: inline-block;
        padding: 8px;
        color: #0366d7;
        background-color: #fff;
        border: 1px solid #0366d7;
        border-radius: 5px;
        width: 40%;
        margin-right: 3%;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
        transition: all 0.3s ease-in-out;
      }

      .btn-otp-go-back:hover {
        color: #fff;
        background-color: #0366d7;
      }

      .btn-otp-continue {
        display: inline-block;
        padding: 8px;
        color: #fff;
        background-color: #0270ed;
        border: 1px solid #0270ed;
        border-radius: 5px;
        width: 40%;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
        transition: all 0.3s ease-in-out;
      }

      .btn-otp-continue:hover {
        color: #fff;
        background-color: #0366d7;
      }

      .resend-otp {
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      .resend-otp:hover {
        color: #000;
        font-weight: bold;
      }

      // button {
      //   padding: 8px;
      //   color: #fff;
      //   font-size: 16px;
      //   font-weight: 500;

      //   border-radius: 10px;
      //   // background: #0270ed;
      //   box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      //   transition: all 0.3s ease-in-out;
      // }

      // button:hover {
      //   background: #0366d7;
      // }
    }

    .login-footer {
      color: $text-color-login;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
