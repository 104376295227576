/* next pation */
.next-page-option-button {
  position: absolute;
  font-size: 14px;
  background-color: #2a3042;
  color: white;
  display: inline-block;
  height: 35px;
  width: 80px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  transition: all 0.3s ease-in-out !important;
}

.next-page-option-button p {
  color: white !important;
  transform: translateX(5px);
  transition: all 0.3s ease-in-out !important;
}

.button-arrow {
  font-size: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out !important;
}

.next-page-option-button:hover .button-arrow {
  opacity: 1;
}
.next-page-option-button:hover p {
  transform: translateX(-4px);
}

.next-page-option-button:hover {
  background-color: #2a3042ed;
}

.next-page-option-button-disabled {
  position: absolute;
  font-size: 14px;
  background-color: #2a3042c9;
  color: white;
  display: inline-block;
  height: 35px;
  width: 80px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  border-radius: 5px;
}

.pervious-button {
  position: absolute;
  font-size: 14px;
  background-color: #ffffff;
  color: #2a3042;
  width: 95px;
  display: inline-block;
  height: 35px;
  position: absolute;
  right: 90px;
  bottom: 0;
  border: 1px solid #2a3042;
  border-radius: 5px;
  transition: all 0.3s ease-in-out !important;
}

.pervious-button p {
  transform: translateX(-4px);
  transition: all 0.3s ease-in-out !important;
}

.pervious-button-arrow {
  font-size: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out !important;
}

.pervious-button:hover p {
  transform: translateX(4px);
  color: #ffffff;
}

.pervious-button:hover .pervious-button-arrow {
  opacity: 1;
}

.pervious-button:hover {
  background: #2a3042;
  color: #ffffff;
}

.sending-template-overlay-loading {
  position: absolute;
  z-index: 223;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0.625rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
}
