.view-team-height {
  height: calc(100vh - 11rem);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;

  .left-side {
    height: calc(100vh - 11rem);
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
    border-radius: 0.6rem;
    display: grid;
    grid-template-rows: 1fr 3fr;

    .Supervisor-team {
      background-color: #f2f2f5;
      border-radius: 0.6rem 0.6rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 0.4rem;
      padding: 2.2rem 0;
      .Supervisor-photo {
        width: 9rem;
        height: 9rem;
        border-radius: 50%;
        background-color: rgb(199, 199, 199);
      }
    }
    h5 {
      font-size: 1rem;
      font-weight: 600;
      color: #6b7889;
    }
    p {
      font-size: 1rem;
      font-weight: 400;
      color: #6b7889;
    }

    .empty-avatar {
      width: 2.25rem;
      height: 2.25rem;
      background-color: #c7c7c7;
      border-radius: 50%;
    }

    .overflow {
      overflow-y: auto;
    }
  }

  .right-side {
    height: calc(100vh - 11rem);
    // display: grid;
    // grid-template-rows: repeat(1 , 1fr);
    overflow-y: auto;
    // gap: 1rem;
    .item {
      background-color: #ffffff;
      box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
      border-radius: 0.6rem;
      .team-conversation {
        background-color: #f2f2f5;
        border-radius: 0.6rem 0.6rem 0 0;

        h5 {
          padding: 1rem;
        }
      }
      .answer {
        padding: 0.4rem 1rem;
        background-color: #f2f2f5;
        border-radius: 0.6rem;
      }
      .answer-details {
        padding: 0.4rem 1rem;
        // background-color: #F2F2F5;
        border: 1px solid #cacaca;
        border-radius: 0.6rem;
        // box-shadow: 0px 4px 4px 0px rgba(175, 175, 175, 0.25);
      }

      th {
        background-color: #f2f2f5;
      }
      th:last-child {
        border-radius: 0rem 0.6rem 0 0;
        border: 0;
      }
      th:first-child {
        border-radius: 0.6rem 0rem 0 0;
      }

      .team-conversation-data {
        padding: 0rem 1rem 1rem 1.2rem;

        p {
          color: #6b7889;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          span {
            color: #8d9eb5;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
          }
        }
      }

      .team-conversation-message {
        border: 1px solid #8d9eb5;
        border-radius: 0.6rem;
        h5,
        th {
          padding: 0.6rem;
          color: #6b7889;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
        }
        p,
        td {
          color: #8d9eb5;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          padding: 0.6rem 1rem;
          text-align: center;
        }
      }
    }
  }
}
.accordion-item {
  cursor: pointer;
}
.tr {
  cursor: pointer !important;
  transition: all 0.3s !important;
}
.tr:hover {
  background-color: #f2f2f5 !important;
}

.h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #6b7889;
}
.edit-view {
  border: 0;
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.625rem;
  background: #6b7889;
  color: #fff;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

// services style
