.table-container-services {
  height: calc(100vh - 11rem) !important ;

  .table-data {
    width: 100% !important;
  }
  .tr {
    cursor: pointer !important;
    transition: all 0.3s !important;
  }
  .tr:hover {
    background-color: #f2f2f5 !important;
  }
}

.table-container-categories {
  height: calc(100vh - 15rem) !important;
  .ellipsis-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    /* Adjust width as needed */
  }
  .tr {
    cursor: pointer !important;
    transition: all 0.3s !important;
  }

  .tr:hover {
    background-color: #f2f2f5 !important;
  }
  .pending-category {
    color: #c62828 !important;
    background-color: #ffebee !important;
  }
  .solved-category {
    color: #388e3c !important;
    background-color: #e9ffef !important;
  }
  .open-category {
    color: #0288d1 !important;
    background-color: #e3f2fd !important;
  }
  .new-category {
    color: #00bfae !important;
    background-color: #e0f7fa !important;
  }
}

// .default-status {
//   // background-color: #d3eefb74;
//   // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   // transition: background-color 0.3s ease, box-shadow 0.3s ease;

//   background: linear-gradient(135deg, #d3eefb74 0%, #a0d8ef 100%);

// }

.default-status {
  background-color: #d3eefb74;
  border-left: 4px solid #1e88e5;
  padding: 10px;
}

.weekdays {
  .form-check-input {
    // width: 1.25rem !important;
    // height: 1.25rem !important;
    padding: 0.5rem !important;
    margin: 0 0.5rem !important;
  }
  .form-check-input:checked {
    background-color: #6b7889 !important;
  }

  .form-time-data {
    margin: 0 1rem !important;
  }
}
.view-selected-services-hours {
  padding: 1rem 4rem !important;
  width: 100%;

  p {
    color: #6b7889;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    span {
      color: #8d9eb5;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
    }
  }

  .table-border {
    border: 1px solid #e0e0e0 !important;
    border-radius: 0.6rem !important;

    th,
    td {
      background-color: #f2f2f588 !important;
      margin: 0;
    }
    th:last-child {
      border-radius: 0rem 0.6rem 0 0;
      border: 0;
    }
    th:first-child {
      border-radius: 0.6rem 0rem 0 0;
    }
  }
}
