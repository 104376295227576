.performance-filters-section {
  background-color: $bg-color;
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
  font-family: $font-family-base;

  h4 {
    color: $text-name-color;
    font-size: 1.25rem;
    font-family: $font-family-base;
    font-weight: 600;
    word-wrap: break-word;
  }

  .performance-chart-container {
    .dropdown-menu {
      padding: 0;
      min-width: 4.375rem;

      button {
        padding: 0.3125rem;
      }
    }
  }

  .overview-container {
    h4 {
      margin-bottom: 0;
    }

    .filters {
      button {
        height: 2.625rem;
        text-transform: capitalize;
        font-size: 1rem;
        box-sizing: border-box;
        background-color: $bg-color;
        border-radius: 0.9375rem;
        display: inline-block;
        padding: 0 5%;
        transition: all 0.3s ease-in-out;

        span.number {
          display: inline-block;
          height: 1.5rem;
          width: 1.5rem;
          line-height: 1.5rem;
          text-align: center;
          border-radius: 50%;
        }
      }

      .inbox {
        margin-bottom: 1rem;
        border: 0.0625rem solid $text-inbox-color;
        color: $text-inbox-color;

        span.number {
          background-color: $text-inbox-color;
          color: #fff;
        }

        &:hover {
          background-color: $text-inbox-color;
          color: #fff;

          span.number {
            background-color: #fff;
            color: $text-inbox-color;
          }
        }
      }

      .active-inbox {
        background-color: $text-inbox-color;
        color: #fff;

        span.number {
          background-color: #fff;
          color: $text-inbox-color;
        }
      }

      .too-late {
        border: 0.0625rem solid $text-toolate-color;
        color: $text-toolate-color;

        span.number {
          background-color: $text-toolate-color;
          color: #fff;
        }

        &:hover {
          background-color: $text-toolate-color;
          color: #fff;

          span.number {
            background-color: #fff;
            color: $text-toolate-color;
          }
        }
      }

      .active-too-late {
        background-color: $text-toolate-color;
        color: #fff;

        span.number {
          background-color: #fff;
          color: $text-toolate-color;
        }
      }

      .danger {
        border: 0.0625rem solid $text-danger-color;
        color: $text-danger-color;

        span.number {
          background-color: $text-danger-color;
          color: #fff;
        }

        &:hover {
          background-color: $text-danger-color;
          color: #fff;

          span.number {
            background-color: #fff;
            color: $text-danger-color;
          }
        }
      }

      .active-danger {
        background-color: $text-danger-color;
        color: #fff;

        span.number {
          background-color: #fff;
          color: $text-danger-color;
        }
      }

      .on-time {
        border: 0.0625rem solid $text-ontime-color;
        color: $text-ontime-color;

        span.number {
          background-color: $text-ontime-color;
          color: #fff;
        }

        &:hover {
          background-color: $text-ontime-color;
          color: #fff;

          span.number {
            background-color: #fff;
            color: $text-ontime-color;
          }
        }
      }

      .active-on-time {
        background-color: $text-ontime-color;
        color: #fff;

        span.number {
          background-color: #fff;
          color: $text-ontime-color;
        }
      }

      .open {
        border: 0.0625rem solid $text-open-color;
        color: $text-open-color;

        span.number {
          background-color: $text-open-color;
          color: #fff;
        }

        &:hover {
          background-color: $text-open-color;
          color: #fff;

          span.number {
            background-color: #fff;
            color: $text-open-color;
          }
        }
      }

      .active-open {
        background-color: $text-open-color;
        color: #fff;

        span.number {
          background-color: #fff;
          color: $text-open-color;
        }
      }

      .open,
      .on-time,
      .danger,
      .too-late,
      .inbox {
        span.loading {
          background-color: white;
          border: 1px solid grey;
        }
      }

      .filters-btns {
        row-gap: 0.75rem;
        column-gap: 1.5rem;

        button {
          flex: 1 1 calc(50% - 0.75rem);
        }
      }
    }

    .dropdown-select-teams {
      .dropdown-toggle {
        font-size: 0.875rem;
        border-radius: 0.3125rem;
      }

      .dropdown-menu {
        min-width: 8.25rem;
        background-color: #f5f6f7;
      }

      .dropdown-divider {
        margin: 0;
      }

      .dropdown-item {
        color: #6b7889;
        font-size: 1rem;
        padding: 0.5rem;

        input {
          display: inline-block;
          margin-right: 0.375rem;
        }

        .form-check-input:checked {
          background-color: #6b7889;
          border-color: #6b7889;
        }
      }
    }
  }

  .team-members-container {
    padding: 0.9375rem 2.0625rem;

    button.accordion-button {
      // margin-top: 0.875rem;
      color: $text-inbox-color;
      background-color: $bg-footer-color;
      font-family: $font-family-base;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      padding: 0.625rem 1.25rem;
    }

    .accordion-body {
      background-color: $bg-footer-color;
    }

    h5 {
      color: $text-inbox-color;
      font-size: 0.875rem;
      font-weight: 700;
    }

    .table-header {
      background-color: $bg-color;
      border-radius: 0.3125rem;
      padding: 0.375rem 5%;

      div {
        width: 40%;
        color: $text-inbox-color;
        font-size: 0.875rem;
        font-weight: 500;
      }

      .message-state-icon {
        width: 10%;
      }
    }

    .table-body {
      background-color: $bg-footer-color;
      border-radius: 0.3125rem;
      padding: 0.5rem 5%;
      transition: all 0.3s ease-in-out;

      span.numbers {
        width: 10%;
        // padding-left: 0.3125rem;
        color: $text-name-color;
        font-size: 0.75rem;
        font-weight: 400;
      }

      div {
        width: 40%;
        color: $text-inbox-color;
        font-size: 0.75rem;
        font-weight: 400;

        .team-member-img {
          position: relative;
          display: inline-block;
          padding-left: 0;

          img {
            width: 1.1875rem;
            height: 1.1875rem;
            border-radius: 50%;
          }

          span.icon {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            display: inline-block;
            height: 0.5rem;
            width: 0.5rem;
            border-radius: 50%;
          }
        }
      }

      &:hover {
        background-color: #dadde1;
        cursor: pointer;
      }
    }

    .active-table-body {
      background-color: #dadde1;
      cursor: pointer;
    }
  }
}
