.modle-chart-report{
    p{
        color:#6B7889 ;
    }
    h3{
        color:#6B7889 ;
        font-size: 32px;
        padding: 0 15px;

        span{
           font-size: 16px; 
           font-weight: 400;
           margin-left: 10px;
        }
    }
    .chart-broadcast-report{
        width: 18px;
        height: 18px;
        border-radius: 50%;
    }
    .read{
        background-color: #546272;

    }
    .delivered{
        background-color: #77879B;
    }
    .sent{
        background-color: #A0B2CA;
    }
    .failed{
        background-color: #CCDEF6;
    }
    .pending{
        background-color: #E0E6E9;
    }

    .type-broadcast-message{
        font-size: 14px;
        color: #5E6A79;

        span{
            font-size: 20px; 
            font-weight: bold;
            margin-right: 5px;
            color: #5E6A79;

        }
    }
        .doughnut-chart{
        width: 260px;
        height: 260px;
        margin-left: 50px;
        // padding: 5px 0;
        }

    .btn-close{
        transform: translateY(-25px);
    }
    // download sheet
.download-sheet {
    background-color: transparent;
    border: 1px solid #6b7889;
    color: #6b7889;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    transition: all 0.3s;
    .download-sheet-icon svg path {
        transition: all 0.3s;
      }
  }
  .download-sheet:hover {
    background-color: #6b7889;
    color: white;
    // transform: translateY(-5px);
    .download-sheet-icon svg path {
      stroke: #ffffff;
    }
  }
}