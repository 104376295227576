.home-page {
  max-height: calc(100vh - 7.75rem);

  .inbox-section {
    background-color: $bg-chatlist-color;
    padding: 2rem 2.1875rem 2.1875rem;
    max-height: calc(100vh - 7.75rem);

    .inbox-header {
      padding: 1.125rem 2.1875rem;
      border-radius: 0.625rem 0.625rem 0 0;
      background: $bg-color;

      h4 {
        color: $text-name-color;
        font-size: 1.25rem;
        font-family: $font-family-base;
        font-weight: 500;
        word-wrap: break-word;
      }
    }

    .inbox-chat-list {
      height: 100%;
      padding: 1.9375rem 2.1875rem;
      background-color: $bg-chat-hover-color;

      .btn-search-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .archived-chats-chatlist {
          .react-datepicker-wrapper {
            .react-datepicker__close-icon {
              right: 0.625rem;
            }
          }

          .archived-chatlist-datepicker {
            padding: 0.625rem;
            display: block;
            // width: 28.25rem;
            width: 100%;
            height: 3.5rem;
            border: none;
            border-radius: 0.3125rem;
          }
        }
      }

      .btns-container {
        display: flex;
        text-align: center;
        font-family: $font-family-base;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: normal;

        .col {
          height: 2.5rem;
          line-height: 2.5rem;
          background-color: $bg-color;
          color: $text-read-unread-color;

          &.btn-all {
            // color: $text-active-read-unread-color;
            border-radius: 0.625rem 0 0 0.625rem;
            // background: $text-name-color;
          }

          &.btn-read {
            border-left: 0.0625rem solid $text-placeholder-color;
            border-right: 0.0625rem solid $text-placeholder-color;
          }

          &.btn-unread {
            border-radius: 0 0.625rem 0.625rem 0;
          }
        }

        div {
          transition: all 0.3s ease-in-out;
        }

        div:hover {
          color: $text-active-read-unread-color;
          background: $text-name-color;
          cursor: pointer;
        }

        .active {
          color: $text-active-read-unread-color;
          background: $text-name-color;
        }
      }

      .search-add-container {
        display: flex;
        gap: 1.875rem;

        input {
          height: 2.5rem;
          border-radius: 0.625rem;
          padding: 0.0625rem 2.125rem;

          &::placeholder {
            color: $text-placeholder-color;
            font-family: $font-family-base;
            font-size: 1.0625rem;
            font-weight: 400;
          }
        }

        span {
          width: 4.875rem;
          height: 2.5rem;
          border-radius: 0.625rem;
          background-color: $text-name-color;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            background-color: #5d5f68;
          }
        }
      }

      .chat-list {
        margin-top: 1.875rem;
        border-radius: 0.3125rem;
        background-color: $bg-color;
        border-radius: 0.3125rem;
        min-height: calc(100vh - 24rem);
        max-height: calc(100vh - 24rem);
        overflow-y: auto;
        padding: 0.625rem 0;
        display: flex;
        flex-direction: column;
        // gap: 1.5rem;

        .chat {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1.5rem;
          transition: all 0.3s ease-in-out;
          border-left: 4px solid $bg-color;

          .user-details {
            display: flex;
            align-items: center;

            .user-img {
              height: 2.9375rem;
              width: 2.9375rem;
              border-radius: 50%;
              overflow: hidden;

              img {
                height: 100%;
              }
            }

            .user-message {
              margin-left: 1.25rem;

              h5 {
                margin-bottom: 0.5rem;
                color: $text-name-color;
                font-family: $font-family-base;
                font-size: 1rem;
                font-weight: 500;
              }

              p {
                color: $text-message-color;
                font-family: $font-family-base;
                font-size: 0.8125rem;
                font-weight: 400;
              }
            }
          }

          .chat-time {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            span {
              margin-bottom: 0.5rem;
              color: $text-message-color;
              font-family: $font-family-base;
              font-size: 0.8125rem;
              font-weight: 400;
            }
          }

          &:hover {
            background-color: $bg-chat-hover-color;
            cursor: pointer;
            border-color: $bg-chat-hover-color;
          }
        }

        .chat-with-new-message {
          border-left: 4px solid #2aaa4a;
          border-radius: 5px;

          &:hover {
            border-color: #2aaa4a;
          }
        }
      }
    }
  }
}
