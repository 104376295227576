.suggested-answer-table {
  height: calc(100vh - 11rem) !important ;
  overflow-y: auto;

  h5 {
    font-size: 1rem;
    font-weight: 600;
    color: #6b7889;
  }
  h4 {
    color: #6b7889;
    font-size: 1rem;
  }

  .table-header {
    background-color: #dedede;
    padding: 1rem 4rem !important;
    border-radius: 0.6rem 0.6rem 0 0;
  }
  .table-data-private-ans {
    background-color: #ffffff;
    padding: 1rem 4rem !important;
    border-radius: 0.6rem;
    // border-bottom: 1px solid #c7c7c7;
    margin: 1rem;
    // border-radius: 0.6rem 0.6rem 0 0 ;
  }
  .table-data {
    background-color: #ffffff;
    padding: 1rem 4rem !important;
    border-radius: 0.6rem;
    // border-bottom: 1px solid #c7c7c7;
    margin: 1rem;
    // border-radius: 0.6rem 0.6rem 0 0 ;
  }
  .table-data:hover {
    background-color: #e3e0e0;
    cursor: pointer;
  }
  .accordion-content {
    width: 95%;
    margin: auto !important;

    .accordion-content-data {
      // background-color: #d3d1d17a;

      padding: 1rem 2rem !important;
      border-radius: 0.6rem;
      // background-color:#e3e0e0 ;
      border: 1px solid #6b7889;
    }

    p {
      color: #6b7889;
      font-size: 1rem;
      font-style: normal;
    }
  }
}
