.forms-table td {
  font-size: 15px;
}

.hover-form-row {
  cursor: pointer;
}

.hover-form-row:hover td {
  background-color: #f9fafc;
}

.filter-heading {
  color: #6b7889;
  font-size: 1.25rem;
  font-family: "Inter", "Poppins", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 0;
}

.fitlers-number {
  font-size: 16px !important;
  height: 2.625rem;
}

.fitlers-number span:nth-of-type(2) {
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
}

.fitlers-number span:nth-of-type(2).loading {
  background-color: white !important;
  border: 1px solid grey !important;
}

.fitlers-number:hover .fitlers-number-all {
  background-color: white !important;
  border: 1px solid black !important;
  color: black !important;
}

.fitlers-number:hover .fitlers-number-danger {
  background-color: white !important;
  border: 1px solid #f46a6a !important;
  color: #f46a6a !important;
}

.fitlers-number:hover .fitlers-number-warning {
  background-color: white !important;
  border: 1px solid #f1b44c !important;
  color: #f1b44c !important;
}

.fitlers-number:hover .fitlers-number-secondary {
  background-color: white !important;
  border: 1px solid #74788d !important;
  color: #74788d !important;
}

.fitlers-number:hover .fitlers-number-success {
  background-color: white !important;
  border: 1px solid #34c38f !important;
  color: #34c38f !important;
}

/* ticket list pge style  */
/* .ticketlist-table th,
.ticketlist-table td {
  font-size: 1rem;
} */
