.options-layout {
  font-family: "Inter", sans-serif;
  position: absolute;
  top: 53%;
  left: 53%;
  transform: translate(-42%, -46%);
  width: 65%;
  height: 75vh;
  display: flex;
  flex-direction: column;
}

.choose-way-heading {
  font-size: 28px;
  font-weight: 400;
}

.svg {
  width: 60%;
  height: 30vh;
}

.template-sending-option {
  position: relative;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 0px 5px 0px #0000001a;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 596px;
  height: 50vh;
  margin-top: 20px;

  transition: 0.3s ease;
}

.btn-sending-option p {
  color: #2a3042;
  font-weight: 400;
  font-size: 22px;
}

.template-sending-option:hover {
  border-color: #c5c3c3;
  transform: translateY(-6px);
}

.template-sending-option:focus {
  border: 1px solid #2aaa4a;
}

.warning-mark {
  border: 0;
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a3042;
  border-radius: 50%;
  margin: 0;
}

.single-option-style {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #2a3042;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
}

.selected-single-option-style {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2aaa4a;
  border: 1px solid #2aaa4a;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
}

.next-enter-data {
  background-color: #2a3042;
  height: 35px;
  width: 80px;
  color: white;
  font-size: 14px;
  display: inline-block;
  padding: 4px 20px;
  right: 0;
  bottom: 0;
  border: 0;
  border-radius: 5px;

  transition: all 0.3s ease-in-out;
}

.next-enter-data:hover {
  background-color: #3f4861;
}

.btn-disabled {
  background-color: #2a3042c9;
  cursor: no-drop;
}

.btn-disabled:hover {
  background-color: #2a3042c9;
}

.pervious-enter-data {
  font-size: 14px;
  background-color: #ffffff;
  color: #2a3042;
  width: 90px;
  display: inline-block;
  height: 35px;
  right: 120px;
  bottom: 0;
  border: 1px solid #2a3042;

  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.pervious-enter-data:hover {
  background-color: #f2f2f2;
  border: 1px solid #3f4861;
}
