.audio-player {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.controls {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-pause {
  background-color: #007aff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.progress {
  flex: 3;
  height: 5px;
  background-color: #ccc;
  position: relative;
  margin: 0 10px;
}

.progress-bar {
  height: 100%;
  background-color: #007aff;
}

.time {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #777;
}

.current-time,
.total-time {
  padding: 0 5px;
}
