.single-message-image-preview {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.single-image-message-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.single-message-image-preview:hover .single-image-message-overlay {
  opacity: 1;
}

.single-image-message-overlay i {
  font-size: 2rem;
  color: white;
}
