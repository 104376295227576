#layout-wrapper {
  direction: ltr;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr auto; /* Adjust rows to have flexible middle content */
  max-height: 100vh;
  min-height: 100vh;
  background-color: #fff;
}

.header {
  grid-column: 3 / -1;
  grid-row: 1; /* Header in the first row */
  height: 2.5rem; /* Fixed height for the header */
  background-color: $bg-header-color;
  position: relative;
}

.sidebar {
  grid-column: 1 / span 2;
  grid-row: 1 / -1; /* Sidebar spanning the entire height */
  background-color: #f4f4f4;
  height: calc(100% - 2.5rem); /* To account for header height */
}

.content {
  grid-column: 3 / -1;
  grid-row: 2; /* Content in the second row, fills the space between header and footer */
  background-color: #ebedf0;
  padding: 0 0.3125rem;
  overflow-y: auto; /* Allow scrolling if the content exceeds the available space */
}

.footer {
  grid-column: 1 / -1;
  grid-row: 3; /* Footer in the third row */
  background-color: #ddd;
  padding: 0.625rem 1.25rem;
  align-self: flex-end;
  min-height: 2.5rem;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.1875rem;
}

::-webkit-scrollbar-track {
  background: $bg-chatlist-color;
  border-radius: 0.5rem 0.5rem 0.9375rem 0.9375rem;
}

::-webkit-scrollbar-thumb {
  background: $text-name-color;
  border-radius: 0.5rem 0.5rem 0.9375rem 0.9375rem;
}
