/* Base font size */
html {
  font-size: 16px; /* Default font size */
}

/* Adjust font size for higher resolutions and zoom levels */
@media (min-width: 1440px) {
  html {
    font-size: 18px; /* Slightly larger for wider screens */
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 16px; /* Larger for very wide screens */
  }
}

/* Adjust font size for smaller screens and higher zoom levels */
@media (max-width: 1280px) {
  html {
    font-size: 12px; /* Smaller for narrower screens */
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 10px; /* Smaller for even narrower screens */
  }
}

/* Adjust font size for high DPI (density) displays */
@media (min-resolution: 120dpi) and (min-width: 1920px) {
  html {
    font-size: 16px; /* Adjust for high DPI displays with wide screens */
  }
}

@media (min-resolution: 150dpi) and (min-width: 1920px) {
  html {
    font-size: 14px; /* Further adjust for even higher DPI displays */
  }
}

/* Adjust for higher browser zoom levels */
@media (max-width: 1536px) and (min-resolution: 120dpi) {
  html {
    font-size: 12px; /* Adjust font size for high zoom levels */
  }
}

@media (max-width: 1536px) and (min-resolution: 150dpi) {
  html {
    font-size: 10px; /* Further adjust for even higher zoom levels */
  }
}
