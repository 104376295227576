@import url(http://fonts.googleapis.com/earlyaccess/droidarabickufi.css);
.Upload-File-Steps {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 87%;
}
.file-stepper-bg {
  width: 80%;
  background-color: #f5f5f9;
  /* z-index: -2; */
  padding: 30% 20px;
}
/* steper style */
/* =============================================== */
.step-active {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #2a3042;
  /* border-radius: 50%; */
  z-index: 1;
  color: white;
}
.step-complete {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #2aaa4a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.number-steps {
  width: 1.5rem;
  height: 1.5rem;
  color: #2a3042;
  border: 1px solid #2a3042;
  z-index: 999;
}
.all-number::after {
  content: "";
  position: absolute;
  /* z-index: -1; */
  height: 110%;
  top: -15px;
  left: 12px;
  background-color: #2a3042;
  width: 1px;
}
.number:not(:last-child)::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background-color: #2a3042;
  border-radius: 50%;
  left: 9px;
  top: 45px;
  /* z-index: -1; */
}
/* ==========================================================*/
/* error add file */
.input-style {
  border: 2px dashed #2aaa4a;
}
.error-input-style {
  border: 2px dashed red;
  /* animation: erreor 0.5s ease; */
}

@keyframes erreor {
  0% {
    transform: translatey(0);
  }
  25% {
    transform: translatey(-5px);
  }
  50% {
    transform: translatey(5px);
  }
  75% {
    transform: translatey(-5px);
  }
  100% {
    transform: translatey(0);
  }
}
.file-load-present {
  animation: upload 0.7s ease;
}
@keyframes upload {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.column-number p {
  color: #2aaa4a;
}
.error-column-number {
  border: 2px dashed red;
  /* animation: erreor 0.5s ease; */
  color: red;
}
.error-column-number p {
  color: red;
}
/* =================================================== */
.country-drop-down input {
  margin: 0;
  width: 50px;
}
.country-drop-down
  .react-international-phone-input-container
  .react-international-phone-input {
  border-left: 0 !important;
}
.country-drop-down .react-international-phone-country-selector {
  width: 35px;
}
.country-drop-down .react-international-phone-country-selector-button {
  border-right: 0 !important;
}
/* =========================================================*/
/* chose temp */
.temp-name:hover {
  background-color: #f0f0f0 !important;
  border-color: #999898;
  /* transform: translateY(-5px); */
}
/* ============================================ */
/* preview template */
.dropdown-toggle-temp-preview-var button {
  border: none;
  background-color: transparent !important;
  color: #2a3042;
}
.dropdown-toggle-temp-preview-var button:hover {
  background-color: transparent !important;
  color: #2a3042 !important;
}
.dropdown-toggle-temp-preview-var:focus {
  color: #2a3042 !important;
}
.dropdown-toggle-temp-preview-var:active {
  color: #2a3042 !important;
}
.dropdown-toggle-temp-preview-var button:active {
  background-color: transparent !important;
  /* background-color: #2aaa4a; */
  color: #2a3042 !important;
}
.dropdown-toggle-temp-preview-var button:focus {
  color: #2a3042 !important;
}

.dropdown-toggle-temp-preview-var button:hover {
  color: #2a3042 !important;
}

.dropdown-toggle-temp-preview-var button:focus-visible {
  background-color: transparent !important;
  color: #2a3042 !important;
}
.dropdown-toggle-temp-preview-var .DropdownItem {
  width: 250px !important;
}

.buttons-preview-template:hover {
  background-color: #2a3042 !important;
  color: #ffffff !important;
  /* transform: translateY(-5px) !important; */
}

.pop-up-confirm {
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 4px 0px #0000001b;
  z-index: 1000;
}

.pop-up-confirm .cancel-button {
  transition: all 0.3s;
}

.pop-up-confirm .cancel-button:hover {
  background-color: #2a3042 !important;
  color: #ffffff !important;
  /* transform: translateY(-5px); */
}

.pop-up-confirm .send-template {
  transition: all 0.3s !important;
}

.send-template:hover {
  /* transform: translateY(-5px) !important; */
}

.highlight {
  color: #2aaa4a;
  font-weight: bold;
  color: black;
  padding: 0px 3px;
}
/* ================================================================ */
/* finished */

.finish-page-conatiner {
  font-family: "Inter", sans-serif;
}

.finish-page-conatiner .btn-finish-action {
  width: 329px;
  height: 43px;
  flex-shrink: 0;
  background: #2a3042;
}

.finish-page-conatiner .btn-finish-new-broadcast:hover {
  background-color: #fff;
  color: #2a3042;
  border-color: #2a3042;
}

.finish-page-conatiner .btn-finish-actiontypography {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.22px;
}
.finish-page-conatiner .btn-finish-home {
  transition: all 0.3s;
  border: 1px solid #212534;
}
.finish-page-conatiner .btn-finish-home:hover {
  background-color: #2a3042 !important;
  color: white !important;
}
.finished-animation .step-complete .complete {
  font-size: 72px;
}
.finished-animation .text-center {
  animation: scaleUp 1s forwards;
}
.finished-animation .text-center {
  transform: scale(0);
  animation: scaleUp 0.5s forwards;
}
@keyframes scaleUp {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
