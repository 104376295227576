.user-profile-page {
  // padding: 2rem 2.1875rem 2.1875rem;
  height: calc(100vh - 8rem) !important;

  overflow-x: hidden !important;
  overflow-y: auto;
  .profile {
    // margin: auto !important;;
    // height: calc(100vh - 14rem) !important;
    // display: grid;
    // grid-template-rows: (2fr, 1fr);
    // gap: 0.6rem;

    .profile-item {
      border-radius: 0.6rem;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 4px 4px 0px rgba(107, 120, 137, 0.25);
    }
  }
  .upload-profile-photo {
    .photo-container {
      position: relative;
      display: inline-block;
    }

    .profile-photo {
      display: block;
      transition: opacity 0.3s ease;
    }

    .delete-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // background-color: rgba(255, 255, 255, 0.7);
      padding: 10px;
      border-radius: 50%;
      font-size: 1.5em;
      display: none !important;
      cursor: pointer;
    }

    .photo-container:hover .profile-photo {
      opacity: 0.5;
    }

    .photo-container:hover .delete-icon {
      display: block !important;
    }

    .delete-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 5.625rem;
      height: 5.625rem;
      border-radius: 50%;

      // object-fit: cover;
    }
    .avatar-img {
      width: 5.625rem;
      height: 5.625rem;
      background-color: #ccc;
      border-radius: 50%;
    }
    button {
      border: 0;
      outline: 0;
      background: #f0effa;
      padding: 0.4rem 1rem;
      font-size: 0.725rem;
      color: #6b7889;
      font-weight: 500;
      border-radius: 0.4rem;
    }
    h4 {
      color: #6b7889;
      font-size: 1.125rem;
      font-weight: 600;
    }
    p {
      color: #6b7889;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
  .change-user-name {
    h4 {
      color: #6b7889;
      font-size: 1.125rem;
      font-weight: 600;
      text-align: left !important;
    }
  }
  .input {
    visibility: hidden;
    display: none;
  }
}
