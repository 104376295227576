.steps {
  position: relative;
  font-size: 14px;
}

.steps::after {
  content: "";
  position: absolute;
  /* z-index: -1; */
  height: 110%;
  top: -15px;
  left: 12px;
  background-color: #2a3042;
  width: 1px;
}

.step {
  position: relative;
}

.step h5 {
  font-size: 20px;
}

.step:not(:last-child)::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background-color: #2a3042;
  border-radius: 50%;
  left: 9px;
  top: 45px;
  /* z-index: -1; */
}

.normal-step {
  width: 1.5rem;
  height: 1.5rem;
  padding: 5px 10px;
  border-radius: 50%;
  color: #2a3042;
  background-color: #f5f5f9;
  border: 1px solid #2a3042;
  z-index: 999;
}

.active-step {
  width: 1.5rem;
  padding: 5px 10px;
  height: 1.5rem;
  background-color: #2a3042;
  border-radius: 50%;
  color: white;
  z-index: 1;
}

.completed-step {
  width: 1.5rem;
  height: 1.5rem;
  padding: 5px 10px;
  background-color: #2aaa4a;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2aaa4a;
  border: none;
  z-index: 1;
}
