.logout-popup{
    max-width: 406px;
    height: 262px;

  
     
    h4{
        color: #6B7889;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .cancel-logout{
        color: #6B7889;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
        letter-spacing: -0.368px;
        border-color: #6B7889;
        transition: all 0.3s ease;
    }
    .cancel-logout:hover{
        color: #6B7889;
        // border: 1px solid #d8d5d5 !important;
        background-color: #eeeded !important;
    }
    .action-logout{
        margin: 30px 0 20px 0 !important;
    }

    .btn-close{
        transition: all 0.3s ease !important;
      }
    button{
        transition: all 0.3s !important;
      }
}