.header {
  .tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 95%;
    max-width: 95%;
    transition: all 0.3s ease-in-out;

    &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.2rem;
      transition: all 0.3s ease;
    }

    &:hover::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    .tab {
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 0.5rem;

      max-width: 10rem;
      min-width: 10rem;
      min-height: 2.3125rem;
      font-size: 0.75rem;

      flex-grow: 1;
      border-radius: 0.625rem 0.625rem 0 0;

      z-index: 1;
      background-color: $bg-chat-hover-color;

      transition: all 0.3s ease-in-out;

      // &:not(:first-child) {
      //   margin-left: -0.5625rem;
      // }

      .tab-status {
        width: 20%;
        min-width: 20%;
        text-align: center;
      }

      .tab-name {
        width: 60%;
        max-width: 65%;
      }

      .tab-close {
        width: 20%;
        min-width: 20%;
        height: 100%;
        text-align: center;
      }

      &:hover {
        z-index: 2;
        background-color: $bg-header-active-tab;
        cursor: pointer;
      }

      .mdi-close {
        border-radius: 50%;
        height: 15px;
        width: 15px;
        line-height: 15px;
        text-align: center;
        transition: all 0.3s ease-in-out;
      }

      .mdi-close:hover {
        // color: #f5f5f5;
        background-color: #ffffff;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        line-height: 15px;
        text-align: center;
      }
    }

    .active {
      z-index: 2;
      background-color: $bg-header-active-tab;
    }

    .add-new-chat {
      background-color: transparent;
      // color: white;
      border-radius: 0.625rem 0.625rem 0 0;
      line-height: 2.3125rem;
      width: 2.3125rem;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        // z-index: 2;
        background-color: $bg-header-active-tab;
        cursor: pointer;
      }
    }
  }

  .notfications-in-header {
    position: absolute;
    top: 0 !important;
    right: 10px !important;
  }
}
