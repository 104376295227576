.template-preview-container,
.fields-container {
  background-color: #ffffff;
  padding: 23px 30px;
  border-radius: 15px;
}

.template-preview-wrapper {
  max-height: 75vh;
  overflow-y: auto;
  padding: 0 5px;
}

.template-preview-wrapper::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.template-preview-wrapper::-webkit-scrollbar-thumb {
  background-color: #f5f5f9;
  border-radius: 6.5px;
  border: none;
  transition: background-color 0.3s ease;
}
.template-preview-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #e0e0e0;
}

.template-preview-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.template-preview-wrapper-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #f5f5f9 transparent;
}

.template-preview-wrapper-scrollbar:hover {
  scrollbar-color: #e0e0e0 transparent;
}

.template-preview-container .text-container {
  background-color: #f5f5f9;
  padding: 13px 40px;
  border-radius: 15px 15px 0 0;
  font-size: 16px;
}

.add-fields-btn {
  border-radius: 10px;
  padding: 6px 30px;
  background-color: #2a3042;
  border: none;
}

.add-fields-btn:hover {
  background-color: #212635;
}

.fileds-order {
  font-size: 24px;
}

.user-fields-conatiner .react-international-phone-country-selector-button {
  border-radius: 5px;
  width: 70px;
}

.user-fields-conatiner .react-international-phone-country-selector-dropdown {
  z-index: 100;
}

.user-fields-conatiner .react-international-phone-input {
  margin-left: 10px;
  width: 80%;
}

.user-fields-conatiner input,
.user-fields-conatiner label {
  background-color: #f5f5f9;
  border-radius: 5px !important;
}

.user-fields-conatiner label {
  min-width: 70px;
  justify-content: center;
}

.user-fields-conatiner i {
  color: #9ea7b2;
  cursor: pointer;
}

.user-fields-conatiner i:hover {
  color: rgb(201, 142, 142);
}

.user-fields-conatiner .btn-send-file {
  padding: 5px 10px;
  background-color: #212635;
  color: white;
  border-radius: 7px;
  border: none;
}

.user-fields-conatiner .btn-send-file:hover {
  background-color: #191d29;
  color: white;
}

.user-fields-conatiner .btn-send-file i {
  color: white;
}

.user-fields-conatiner .btn-send-file:hover i {
  color: rgb(201, 142, 142);
}

.user-fields-conatiner .btn-upload-disabled:hover {
  cursor: no-drop;
}

.user-fields-conatiner .btn-upload-disabled:hover i {
  color: white;
  cursor: no-drop;
}

.user-fields-conatiner .btn-send-file-uploaded {
  background-color: #34c38f;
}

.user-fields-conatiner .btn-send-file-uploaded:hover {
  background-color: #30ba88;
}

.preivous-btn-enter-data {
  font-size: 14px;
  background-color: #ffffff;
  color: #2a3042;
  width: 90px;
  display: inline-block;
  height: 35px;
  border: 1px solid #2a3042;

  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.preivous-btn-enter-data:hover {
  background-color: #f2f2f2;
  border: 1px solid #3f4861;
}

.next-btn-enter-data {
  background-color: #2a3042;
  height: 35px;
  width: 80px;
  color: white;
  font-size: 14px;
  display: inline-block;
  padding: 4px 20px;
  border: 0;
  border-radius: 5px;

  transition: all 0.3s ease-in-out;
}

.next-btn-enter-data:hover {
  background-color: #3f4861;
}

.fields-container .wizard .steps > ul > li a {
  padding: 0.2rem 0.7rem;
  color: #2a3042;
}

.fields-container .wizard .steps .number {
  border: none;
  color: #2a3042;
}

.fields-container li.current {
  background-color: #7e8998;
  color: white;
}

.fields-container .wizard .steps .current a.current,
.fields-container .wizard .steps .current a.active {
  background-color: #7e8998;
  color: white;
}

.fields-container .wizard .steps .current a .number {
  background-color: transparent;
  border: none;
}

.fields-container .wizard .steps .current:hover a .number {
  background-color: transparent;
  color: white;
}

.fields-container .wizard > .actions ul li a {
  background-color: #2a3042;
}

.upload-single-file button {
  font-size: 12px;
}

.fields-container .wizard > .actions ul li.disabled a,
.upload-single-file button {
  background-color: #585d6b;
}

.fields-container .wizard > .actions ul li.disabled:hover a,
.upload-single-file button:disabled {
  background-color: #6b707d;
}

.fields-container .wizard > .actions ul li:hover a,
.upload-single-file button:hover {
  background-color: #222735;
}
