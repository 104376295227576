//
// _footer.scss
//

// .footer {
//   bottom: 0;
//   padding: 1.25rem calc(#{$grid-gutter-width} * 0.75);
//   position: absolute;
//   right: 0;
//   color: var(--#{$prefix}footer-color);
//   left: 15.625rem;
//   height: $footer-height;
//   background-color: var(--#{$prefix}footer-bg);

//   @media (max-width: 61.9988rem) {
//     left: 0;
//   }
// }

// // Enlarge menu
// .vertical-collpsed {
//   .footer {
//     left: $sidebar-collapsed-width;

//     @media (max-width: 61.9988rem) {
//       left: 0;
//     }
//   }
// }

// body[data-layout="horizontal"] {
//   .footer {
//     left: 0 !important;
//   }
// }

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: 2.5rem;

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .copyright {
      display: flex;
      align-items: center;
      justify-content: center;

      &-year {
        color: $text-footer-color;
        font-size: 0.75rem;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        margin-right: 0.3125rem;
      }

      &-dot {
        color: $text-footer-color;
        font-size: 0.9375rem;
        font-family: "Inter", sans-serif;
        font-weight: 400;
      }
    }

    .copyright-logo {
      width: 2.125rem;
      height: 1.125rem;
      margin-left: 1.25rem;

      div {
        position: absolute;
        background: $text-footer-color;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $bg-footer-color;
    z-index: -1;
  }
}
