.contact-info {
  border-radius: 5px;
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-info:hover {
  background-color: rgb(41, 41, 41);
  border-radius: 5px;
  padding: 10px 0;
  color: white;
}
