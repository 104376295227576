.sidebar {
  padding: 3.125rem 0;
  padding-top: 0.9375rem;
  background-color: $bg-color;
  font-family: $font-family-base;

  .avatar-img {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
  }

  .logo {
    text-align: center;
  }

  .sidebar-content {
    height: calc(100vh - 10.125rem);
    // padding-top: 7.5rem;

    .nav-items {
      position: relative;
      transition: all 0.3s ease-in-out;

      .menu-item {
        position: relative;
        transition: all 0.3s ease-in-out;

        h6 {
          color: #6b7889;
          font-size: 1.125rem;
          font-weight: 500;
          margin-bottom: 0;
          transition: all 0.3s ease-in-out;
          position: relative;
          cursor: pointer;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0rem;
            border-radius: 0 0.75rem 0.75rem 0;
            background-color: #6b7889;
          }

          &:hover {
            background-color: #ebedf0;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 0.3125rem;
              border-radius: 0 0.75rem 0.75rem 0;
              background-color: #6b7889;
            }
          }
        }

        .sub-menu {
          color: #8d9eb5;
          font-size: 1rem;
          font-weight: 400;
          height: 0;
          overflow: hidden;

          transition: all 0.3s ease-in-out;

          span {
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: #6b7889;
              font-weight: 500;
              transition: all 0.3s;
              position: relative;

              &::before {
                content: "";
                display: inline-block;
                width: 6px;
                height: 6px;
                background-color: #6b7889;
                border-radius: 50%;
                position: absolute;
                left: 4px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

        .active-sub-menu {
          color: #6b7889;
          font-weight: 500;
          transition: all 0.3s;
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: #6b7889;
            border-radius: 50%;
            position: absolute;
            left: 4px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .active-menu-item {
        h6 {
          background-color: #ebedf0;
        }
      }
    }

    .profile-card-container {
      color: #6b7889;
      cursor: pointer;

      .user-status {
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: blue;
        border: 1px solid white;
        position: absolute;
        top: 3px;
        right: 1px;
      }

      h6 {
        font-size: 1.125rem;
        font-weight: 500;
      }

      .dropdown-menu {
        background: #f5f6f7;
        width: 14.125rem;
        border-radius: 0.4375rem;
        filter: drop-shadow(0.3242rem 0.3242rem 1.2159rem rgba(0, 0, 0, 0.2));
      }

      .dropdown-item {
        padding-left: 3.5rem;
        padding-top: 0.4375rem;
        font-size: 1rem;
        padding-bottom: 0.4375rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: #42526d;
          font-weight: 500;

          background-color: #ebeef3;
        }
      }

      .user-details,
      .name {
        span {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }

      .name {
        padding: 0.375rem 1.5rem 0.5rem;
        padding-left: 0.75rem;
        border-bottom: 0.0625rem solid #dfe4ed;
      }

      .status {
        border-top: 0.0625rem solid #dfe4ed;
        padding-top: 0.5625rem;
        padding-bottom: 0.75rem;
        padding-left: 1.3125rem;

        &-icon {
          margin-right: 1.4375rem;
        }

        &-header {
          width: 100%;
        }
      }

      .sub-dropdown-menu {
        background-color: #ffffff;
        border-radius: 0.4375rem;
        width: 180;
        box-shadow: -0.6485rem 0.1621rem 0.9971rem 0rem rgba(0, 0, 0, 0.15);

        button {
          display: flex;
          align-items: center;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 0.6875rem;

          h6 {
            margin-bottom: 0;
            margin-left: 0.375rem;
          }

          &:hover {
            background-color: #dfe4ed;
          }
        }
      }

      .logout {
        border-top: 0.0625rem solid #dfe4ed;
        padding-top: 0.5625rem;
        padding-bottom: 0.5625rem;
        padding-left: 1.3125rem;

        .logout-icon {
          margin-right: 1rem;
        }
      }
    }
  }
}

.disabled-user-status {
  cursor: not-allowed !important;
}

/* Mobile styles (up to 767px) */
@media (max-width: 767px) {
  .container {
    padding: 10px;
  }

  .header {
    font-size: 1.5rem;
  }

  .image-item {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 900px) {
  .sidebar {
    .sidebar-content {
      .nav-items {
        .menu-item {
          h6 {
            padding: 0.34rem 0 0.34rem 1.25rem;
          }

          .sub-menu {
            // padding-left: 4.1875rem;
          }
        }
      }
    }
  }
}

@media (min-width: 900px) and (max-width: 1023px) {
  .sidebar {
    .sidebar-content {
      .nav-items {
        .menu-item {
          h6 {
            padding: 0.6875rem 0 0.6875rem 2.5rem;
          }

          .sub-menu {
          }
        }
      }
    }
  }
}
