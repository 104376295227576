.broadcast-report {
  background-color: $bg-chatlist-color;
  // padding: 3.18rem 3.18rem 3.18rem ;
  padding: 2rem 2.1875rem 2.1875rem;
  // height: calc(100vh - 12rem) !important ;
  overflow: hidden !important;
  border-radius: 0.6rem !important;

  .broadcast-table-height {
    height: calc(100vh - 22rem) !important ;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .broadcast-table-height-loading {
    height: calc(100vh - 24rem) !important ;
    overflow: hidden;
  }

  .broadcast-report-header {
    padding: 2.1rem 3.1rem;
    border-radius: 10px 10px 0 0;
    background: $bg-color;
    h4 {
      color: $text-name-color;
      font-size: 1.5rem;
      font-family: $font-family-base;
      font-weight: 500;
    }

    .text-header {
      color: #6b7889 !important;
      font-size: 1rem !important;
      font-style: normal;
      font-weight: 500;
    }
    .text-body {
      // color: #6B7889 !important;
      font-size: 1rem !important;
      font-style: normal;
    }
  }
  .broadcast-filter-date-picker {
    padding: 0rem 2rem;
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 0.5rem;
    color: #6b7889;
    transition: transform 0.3s ease-out;
    height: 3rem;
    flex-grow: 1;
    align-items: stretch !important;
  }

  //table
  .table-style {
    .row-report-table {
      padding: 0.75rem !important;
      text-align: center;
      border-bottom: 1px solid #ddd !important;
      .text-header {
        color: #6b7889 !important;
        font-size: 1rem !important;
        font-style: normal;
        font-weight: 500;
      }
      .text-body {
        color: #6b7889 !important;
        font-size: 1rem !important;
        font-style: normal;
      }
      .view-report-button {
        border: 0;
        background-color: transparent;
        padding: 3px 12px;
        // color: white;
        border-radius: 5px;
        transition: all 0.3s ease-in-out !important;
        display: flex;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
      }
    }
    .loading {
      height: calc(100vh - 100px) !important ;
      transform: translatey(-20%);
      overflow: hidden !important;
    }
    .table-border {
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      overflow-x: hidden;
    }
  }

  .reset-filters-report {
    // padding: 0.2rem 0.75rem;
    border-radius: 0.625rem;
    background: #6b7889 !important;
    color: #fff;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    border: 1px solid #6b7889 !important;
    height: 3rem !important;
    width: 3rem;
  }
  .reset-filters-report:hover {
    background: #5c6877 !important;
    transition: all 0.3s ease-in-out;
  }
}
.table-broadcast {
  border: 1px solid #ddd;
}
//pagination
.pagination .active a {
  background-color: #6b7889 !important ;
  color: white !important;
}
.pagination-rounded .page-link {
  width: 1.375rem !important;
  height: 1.375rem !important;
  font-size: 0.8rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #495057 !important;
}
.page-item .page-link {
  background-color: transparent !important;
}
