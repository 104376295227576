.ticket-client-page {
  font-family: Inter;
}

.ticket-page-header-container {
  background-color: #6b7889;
  color: white;
}

.ticket-client-page .ticket-client-heading {
  /* background-color: #6b7889; */
  font-weight: 700;
  font-size: 2.5rem;
}

.ticket-client-page-heading-paragraph {
  font-size: 1rem;
}

.ticket-client-page-feedback {
  color: #6b7889;
  min-width: 25%;
  top: 30%;
  right: 5%;
  z-index: 1111;
}

.ticket-client-page-feedback .single-feedback {
  padding: 0 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.ticket-client-page-feedback .single-feedback:hover {
  background: #e1dfdf;
  border-radius: 10px;
}

.ticket-client-page-feedback .single-feedback.active {
  background: #e1dfdf;
  border-radius: 10px;
}

.ticket-client-page-third-col {
  padding-top: 15rem;
}

.ticket-client-page .accordion-header button,
.ticket-client-page .accordion-body,
.ticket-client-page .accordion-body h5,
.ticket-client-page .accordion-body h6,
.ticket-client-page .accordion-body input {
  font-size: 1rem;
}

.client-ticket-custom-accordion-header {
  background-color: #fff;
  padding: 4px;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.client-ticket-custom-accordion-header button {
  background-color: white !important;
}

.client-ticket-custom-accordion-body {
  background-color: #f8f9fa;
  padding: 16px;
  border-radius: 0.5rem;
  border: 0.0625rem solid #dee2e6;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
}

@media (max-width: 47.9375rem) {
  .ticket-client-page .ticket-client-heading {
    /* background-color: #6b7889; */
    font-size: 1.5rem;
  }

  .ticket-client-page-heading-paragraph {
    font-size: 0.75rem;
  }

  .ticket-page-header-container {
    margin-bottom: 20.75rem;
  }

  .ticket-client-page-feedback {
    min-width: 80%;
    top: 75%;
    right: 10%;
  }

  .ticket-client-page-third-col {
    padding-top: 0;
  }
}
