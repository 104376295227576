.ticket-dashboard {
  height: calc(100vh - 5rem) !important ;
  overflow-y: auto;

  .ticket-overview {
    .ticket-overview-item {
      background-color: white;
      box-shadow: 0px 3.5px 5.5px 0px #0000000d;
      border-radius: 0.9rem !important;
      padding: 0.5rem 1rem;

      .ticket-overview-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.8rem;
        height: 2.8rem;
        padding: 0.6rem;
        border-radius: 0.8rem;
        box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
      }
      .bg-total {
        background: rgba(56, 121, 240, 1);
      }
      .bg-solved {
        background: rgba(42, 170, 74, 1);
      }
      .bg-unsolved {
        background: rgba(223, 65, 40, 1);
      }
      .bg-average {
        background: rgba(107, 120, 137, 1);
      }

      .ticket-overview-item-heading {
        font-size: 0.875rem;
        // color: #6B7889;
        color: #a0aec0;
        font-weight: 600;
      }
      .ticket-overview-item-details {
        font-size: 1rem;
        font-weight: 700;
        color: #6b7889;
      }
      .ticket-overview-number {
        font-size: 0.675rem;
        // color: #6B7889;
        color: #a0aec0;
        // font-weight: 600;
      }
      .customer-satisfaction-details {
        font-size: 1.375rem;
        font-weight: 700;
        color: #6b7889;
      }
      .heading-border {
        border-bottom: 0.6px solid #e5e5ef;
      }
      .border-priority {
        border-top: 0.6px solid rgba(229, 229, 239, 1);
      }

      .chart-zero-values-p {
        color: #9291a5;
        margin-right: 1rem !important;
        font-size: 14px;
        font-weight: bold;
      }
      .chart-zero-values-span {
        color: #6b7889;
        font-size: 0.81rem;
        margin-right: 0.6rem;
        font-weight: bold;
      }
      .charts-heading {
        color: #6b7889;
        font-size: 1.125rem;
        font-weight: 700;
      }
    }
    .all {
      padding: 1rem 1.2rem !important;
    }
  }
  .ticket-overview-g {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  @media (min-width: 771px) and (max-width: 1100px) {
    .ticket-overview-g {
      grid-template-columns: repeat(2, 1fr);
      // .ticket-overview-item:nth-child(1) {
      //   grid-column: span 2;
      // }
      .ticket-overview-item:last-child {
        grid-column: span 2;
      }
    }
  }
  @media (max-width: 770px) {
    .ticket-overview-g {
      grid-template-columns: repeat(1, 1fr);
    }
    .all-ticket {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  .broadcast-filter-date-picker {
    padding: 0.5rem 1.3rem;
    border: 1px solid #ddd;
    //   width: 100%;
    border-radius: 0.5rem;
    color: #6b7889;
    transition: transform 0.3s ease-out;
    height: 2rem;
  }
  .satisfaction-details-bar {
    width: 13rem;
    background: rgba(229, 229, 239, 1);
    border-radius: 5px;
    height: 0.5rem;
    position: relative;
    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 5px;
    }
    .progress-bar-positive {
      // width: 80%;
      background-color: #2aaa4a;
    }
    .progress-bar-negative {
      // width: 5%;
      background-color: #df4128;
    }
    .progress-bar-neutral {
      // width: 15%;
      background-color: #f8b649;
    }
  }
  .satisfaction-grid {
    position: relative;
  }

  .satisfaction-grid::before,
  .satisfaction-grid::after {
    content: "";
    position: absolute;
    background-color: rgba(229, 229, 239, 1);
  }

  .satisfaction-grid::before {
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    transform: translateY(-50%);
  }

  .satisfaction-grid::after {
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    transform: translateX(-50%);
  }
}
.small-font {
  font-size: 0.8rem;
}
.feedback-style {
  font-size: 0.8rem;
  padding: 0.281rem 0.562rem;
  border-radius: 0.625rem;
  border: 0;
}
.neutral-feedback {
  background: var(--Status-BG-Orange, #fff2dd);
  color: var(--Status-Text-Orange, #d98634);
}
.positive-feedback {
  background: var(--Status-BG-Green, #e9ffef);
  color: var(--Status-Text-Green, #409261);
}
.negative-feedback {
  background-color: #ffcaca;
  color: #ff0404;
}
