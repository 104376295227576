.steps-page-layout {
  display: grid;
  grid-template-columns: 4fr 8fr;
  height: 75vh;
  align-items: center;
}

.stepper-wrapper {
  height: 80%;
  width: 85%;
  background-color: #f5f5f9;
  padding: 30% 20px;
}
