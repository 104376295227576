.field-types .field-card {
  cursor: pointer;
  border: 1px solid #fff;

  transition: all 0.3s;
}

.field-types .field-card:hover {
  border: 1px solid #000;
}

.field-types .field-card-active {
  border: 1px solid #000;
}

.field-types .field-card-header {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}
