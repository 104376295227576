.templates-list-layout {
  font-family: "Inter", sans-serif;
}

.templates-list-layout h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.templates-list {
  padding: 20px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #0000001b;
  gap: 20px;
  max-height: 350px;
  min-height: 350px;
  overflow: auto;
}

.templates-list-loading-container {
  min-height: 350px;
}

.single-template-list-item {
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #2a304280;
  transition: all 0.3s;
}

.single-template-list-item:hover {
  background-color: #f0f0f0;
  border-color: #999898;
  transform: translateY(-2px);
}

.single-template-name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  color: #2a3042;
  text-transform: capitalize;
}

.template-check-icon {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 1px solid #2aaa4a;
  position: relative;
  padding: 0;
  margin: 0;
}

.template-icon {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2aaa4a;
  width: 85%;
  height: 85%;
  border-radius: 50%;
}

.selected-template {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #2a3042;
}
